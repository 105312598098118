.slider {
  margin-bottom: 20px;
}

// @media (min-width: 768px) {
// }

@media (min-width: 1200px) {
  .form {
    // display: flex;
    // justify-content: center;
  }

  .slider {
    // flex-basis: 820px;
    // margin-bottom: 0;
  }
}
