.contacts {
  position: relative;

  background-color: #f2f2f2;

  #contacts {
    position: absolute;
    top: -68px;
  }

  &__content {
    padding-top: 62px;
  }

  &__title {
    margin-bottom: 27px;

    font-size: 35px;
    font-weight: 700;
    line-height: 1.2286;
    text-align: center;
  }

  &__block {
    margin-bottom: 62px;
    padding-top: 24px;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 29px;

    background-color: #fff;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  &__item {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      margin-bottom: 2px;

      font-weight: 700;
      line-height: 1.274;

      color: map-get($colors, 'main');
    }
  }

  &__address {
    line-height: 1.375;
    text-align: center;
  }

  &__phone {
    font-weight: 600;
    line-height: 1.274;

    transition: 0.2s;

    &:active {
      color: map-get($colors, 'main');
    }
  }

  &__email {
    line-height: 1.274;

    transition: 0.2s;

    &:active {
      color: map-get($colors, 'main');
    }
  }

  &__instagram {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 5px;

    border-radius: 50%;
    background-color: #f2f2f2;
  }

  &__map {
    height: 297px;
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media (min-width: 768px) {
  .contacts {
    #contacts {
      top: -79px;
    }

    &__content {
      position: relative;
      padding-top: 0;
    }

    &__block {
      position: absolute;
      z-index: 5;
      top: 59px;
      left: 0;

      width: 277px;
      padding-top: 29px;
      padding-left: 29px;
      padding-right: 24px;
      padding-bottom: 34px;
    }

    &__title {
      text-align: left;
    }

    &__item {
      align-items: flex-start;
    }

    &__address {
      text-align: left;
    }

    &__map {
      height: 524px;

      margin-left: calc((100vw - 720px) / -2 - 20px);
      margin-right: calc((100vw - 720px) / -2 - 20px);
    }
  }
}

@media (min-width: 992px) {
  .contacts {
    &__map {
      height: 524px;
      margin-left: calc((100vw - 960px) / -2 - 20px);
      margin-right: calc((100vw - 960px) / -2 - 20px);
    }
  }
}

@media (min-width: 1200px) {
  .contacts {
    #contacts {
      top: -127px;
    }

    &__block {
      top: 120px;

      width: 414px;
      padding-top: 43px;
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 43px;
    }

    &__list {
      row-gap: 29px;
    }

    &__map {
      height: 665px;

      margin-left: calc((100vw - 1140px) / -2 - 20px);
      margin-right: calc((100vw - 1140px) / -2 - 20px);
    }
  }
}

@media (hover: hover) {
  .contacts {
    &__item {
      a {
        &:hover {
          color: map-get($colors, 'main');
        }
      }
    }
  }
}
