.module {
  width: 20px;
  height: 20px;

  border: 1px solid #b7b7b7;
  border-radius: 50%;
  cursor: pointer;

  appearance: none;

  &:checked {
    border-color: #ff7e1d;

    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Ccircle cx="10" cy="10" r="5" fill="%23FF7E1D" /%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
