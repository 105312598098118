.screen {
  padding-top: 68px;

  background-repeat: no-repeat;
  background-size: cover;

  &__content {
    padding-top: 78px;
    padding-bottom: 275px;
  }

  &__title {
    margin-bottom: 18px;

    font-size: 46px;
    font-weight: 700;
    line-height: 0.978;
    text-align: center;

    color: #fff;
  }

  &__text {
    margin-bottom: 38px;

    font-size: 20px;
    font-weight: 500;
    line-height: 1.2852;
    text-align: center;

    color: #fff;
  }

  &__offer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding-top: 23px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 18px;

    background-color: rgba(249, 249, 249, 0.66);
    backdrop-filter: blur(9px);

    p {
      margin-bottom: 8px;

      font-size: 20px;
      font-weight: 700;
      line-height: 1.2;
      text-align: center;
    }

    strong {
      color: map-get($colors, 'main');
    }

    span {
      display: block;

      font-size: 14px;
      line-height: 1.374;
      text-align: center;

      &::before {
        position: absolute;
        margin-top: -2px;
        margin-left: -12px;

        font-size: 23px;
        font-weight: 500;

        color: map-get($colors, 'second');

        content: '*';
      }
    }
  }

  &__video {
    display: none;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    height: 40px;
    margin-left: 5px;
    padding-left: 24px;
    padding-right: 24px;

    font-size: 12px;
    font-weight: 600;
    text-align: center;

    color: #fff;
    background-color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      background-color: map-get($colors, 'main-dark');
    }
  }
}

// @media (min-width: 576px) {
//   .screen {
//     &__bg {
//       margin-left: calc((100vw - 540px) / -2 - 20px);
//       margin-right: calc((100vw - 540px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 768px) {
  .screen {
    padding-top: 79px;

    &__content {
      padding-top: 102px;
      padding-bottom: 185px;
    }

    &__title {
      margin-bottom: 5px;

      font-size: 65px;
      line-height: 1.215;
      text-align: left;
    }

    &__text {
      max-width: 444px;
      margin-bottom: 45px;

      font-size: 28px;
      text-align: left;
    }

    &__offer {
      width: 550px;
      padding-top: 27px;
      padding-left: 33px;
      padding-right: 33px;
      padding-bottom: 27px;

      p {
        margin-bottom: 9px;

        font-size: 23px;
        text-align: left;
      }

      span {
        position: relative;

        // max-width: 230px;
        padding-left: 14px;

        text-align: left;

        &::before {
          left: 0;
          margin-left: 0;
        }
      }
    }

    &__link {
      height: 45px;

      font-size: 15px;
    }
  }
}

// @media (min-width: 992px) {
//   .screen {
//     &__bg {
//       margin-left: calc((100vw - 960px) / -2 - 20px);
//     }

//     &__image {
//       left: calc((100vw - 960px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 1200px) {
  .screen {
    position: relative;

    padding-top: 127px;

    &__content {
      position: relative;
      z-index: 2;
    }

    &__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      display: flex;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-image: linear-gradient(
        89.53deg,
        rgba(0, 0, 0, 0.71) 6.83%,
        rgba(0, 0, 0, 0) 95.82%
      );

      content: '';
    }

    &__link {
      &:hover {
        background-color: map-get($colors, 'main-dark');
      }
    }
  }
}

// @media (hover: hover) {
//   .screen {
//     &__booking {
//       &:hover {
//         background-color: map-get($colors, 'main-dark');
//       }
//     }

//     &__calculation {
//       &:hover {
//         color: #fff;
//         background-color: map-get($colors, 'main');
//       }
//     }
//   }
// }
