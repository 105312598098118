.module {
  display: flex;
  justify-content: center;
}

.prev {
  position: relative;

  height: 55px;
  width: 55px;
  margin-right: 20px;

  border: 1px solid #fff;

  transition: 0.2s;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 20px;
    height: 2px;

    background-color: #fff;
    transform: translate(-50%, -50%);

    transition: 0.2s;

    content: '';
  }

  &::after {
    position: absolute;
    top: 21px;
    left: 18px;

    width: 12px;
    height: 12px;

    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);

    transition: 0.2s;

    content: '';
  }

  &:active {
    background-color: #fff;

    &::before {
      background-color: #0c6ff9;
    }

    &::after {
      border-color: #0c6ff9;
    }
  }
}

.next {
  height: 55px;
  flex-grow: 1;

  font-size: 18px;
  font-weight: 700;
  text-align: center;

  color: #0c6ff9;
  background-color: #fff;

  transition: 0.2s;

  &:active {
    background-color: darken(#fff, 10%);
  }
}

.confirm {
  height: 55px;
  width: 100%;

  font-size: 18px;
  font-weight: 700;
  text-align: center;

  color: #0c6ff9;
  background-color: #fff;

  transition: 0.2s;

  &:active {
    background-color: darken(#fff, 10%);
  }
}

@media (min-width: 768px) {
  .module {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
}

@media (hover: hover) {
  .prev {
    &:hover {
      background-color: #ff7e1d;
      border-color: #ff7e1d;

      &::before {
        background-color: #fff;
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  .next {
    &:hover {
      color: #fff;
      background-color: #ff7e1d;
    }
  }

  .confirm {
    &:hover {
      color: #fff;
      background-color: #ff7e1d;
    }
  }
}
