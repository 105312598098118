.children {
  background-color: #f2f2f2;

  &__bg-1 {
    display: none;
  }

  &__bg-2 {
    display: none;
  }

  &__content {
    padding-top: 74px;
    padding-bottom: 74px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
    padding-top: 52px;
    padding-bottom: 50px;

    background-color: rgba(255, 255, 255, 0.8);
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 240px;

    strong {
      margin-bottom: 8px;

      font-size: 20px;
      font-weight: 500;
      line-height: 1.42;
      text-align: center;

      color: map-get($colors, 'second');
    }

    span {
      line-height: 1.5;
      text-align: center;
    }
  }
}

@media (min-width: 768px) {
  .children {
    &__content {
      padding-top: 46px;
      padding-bottom: 68px;
    }

    &__list {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      column-gap: 40px;
      padding-top: 37px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 42px;

      strong {
        min-height: 57px;
        margin-bottom: 4px;
      }
    }

    &__item {
      max-width: 33.33333%;
    }
  }
}

@media (min-width: 1200px) {
  .children {
    position: relative;

    &__content {
      padding-top: 50px;
      padding-bottom: 127px;
    }

    &__bg-1 {
      position: absolute;
      top: 20px;
      left: -30px;

      display: flex;
    }

    &__bg-2 {
      position: absolute;
      right: -30px;
      bottom: -10px;

      display: flex;
    }

    &__list {
      justify-content: space-between;
      padding-top: 30px;
      padding-left: 35px;
      padding-right: 35px;
      padding-bottom: 35px;

      strong {
        min-height: auto;

        font-size: 23px;
      }
    }
  }
}
