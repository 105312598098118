.module {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 11px;
  margin-bottom: 14px;
}

.label {
  display: flex;
  align-items: center;
  width: 180px;

  cursor: pointer;

  &.active {
    font-weight: 500;

    color: #ff7e1d;
  }

  input {
    margin-right: 6px;
  }
}

@media (min-width: 768px) {
  .module {
    flex-direction: row;
    justify-content: center;
    column-gap: 30px;
    margin-bottom: 28px;
  }
}

@media (min-width: 1200px) {
  .module {
    justify-content: flex-start;
  }
}
