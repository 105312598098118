.module {
  position: relative;

  display: flex;
  align-items: center;
  height: 55px;
  padding-left: 19px;

  border: 1px solid #c0c0c0;
  background-color: #fff;
  // background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10"%3E%3Cpolyline points="1 3, 6 8, 11 3" stroke="%23999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" /%3E%3C/svg%3E');
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none"%3E%3Cpath d="M12.1953 1.15234H10.9375V0.496094C10.9375 0.254484 10.7416 0.0585938 10.5 0.0585938C10.2584 0.0585938 10.0625 0.254484 10.0625 0.496094V1.15234H3.9375V0.496094C3.9375 0.254484 3.74164 0.0585938 3.5 0.0585938C3.25836 0.0585938 3.0625 0.254484 3.0625 0.496094V1.15234H1.80469C0.809566 1.15234 0 1.96191 0 2.95703V12.2539C0 13.249 0.809566 14.0586 1.80469 14.0586H12.1953C13.1904 14.0586 14 13.249 14 12.2539V2.95703C14 1.96191 13.1904 1.15234 12.1953 1.15234ZM1.80469 2.02734H3.0625V2.46484C3.0625 2.70645 3.25836 2.90234 3.5 2.90234C3.74164 2.90234 3.9375 2.70645 3.9375 2.46484V2.02734H10.0625V2.46484C10.0625 2.70645 10.2584 2.90234 10.5 2.90234C10.7416 2.90234 10.9375 2.70645 10.9375 2.46484V2.02734H12.1953C12.708 2.02734 13.125 2.44439 13.125 2.95703V3.99609H0.875V2.95703C0.875 2.44439 1.29205 2.02734 1.80469 2.02734ZM12.1953 13.1836H1.80469C1.29205 13.1836 0.875 12.7665 0.875 12.2539V4.87109H13.125V12.2539C13.125 12.7665 12.708 13.1836 12.1953 13.1836Z" fill="%23858585"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 16px center;
  // background-size: 10px;
  background-size: 14px;
  cursor: pointer;
}

.name {
  margin-right: 10px;

  color: #858585;
}
