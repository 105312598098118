.module {
  display: flex;
  align-items: center;
  height: 55px;
  padding-left: 19px;
  padding-right: 35px;

  border: 1px solid #c0c0c0;
  background-color: #fff;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10"%3E%3Cpolyline points="1 3, 6 8, 11 3" stroke="%23000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" /%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 18px center;
  background-size: 10px;
  cursor: pointer;

  strong {
    margin-right: auto;

    color: #858585;
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  width: 280px;
  padding-top: 4px;
  padding-left: 18px;

  padding-bottom: 18px;

  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(152, 152, 152, 0.13);
}

.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-height: 200px;
  padding-right: 18px;
  overflow-y: scroll;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid #999999;
  }
}

div.select {
  width: 82px;
  height: 52px;

  border: none;
  color: #0c6ff9;
  background-position: right 0 center;
}

.button {
  align-self: center;
  height: 55px;
  margin-right: 18px;
  padding-left: 52px;
  padding-right: 52px;

  color: #fff;
  background-color: #0c6ff9;

  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
