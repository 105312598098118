.about {
  position: relative;

  padding-top: 164px;

  background-image: linear-gradient(230.87deg, #e6f8f9 0%, #f5f2ea 100%);

  #about {
    position: absolute;
    top: -68px;
  }

  &__content {
    padding-top: 61px;
    padding-bottom: 25px;
  }

  &__bg-1 {
    display: none;
  }

  &__bg-2 {
    display: none;
  }

  &__title {
    margin-bottom: 28px;

    font-size: 35px;
    font-weight: 700;
    line-height: 1.2286;
    text-align: center;
  }

  &__list {
    position: relative;

    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 39px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 17px;

    background-color: rgba(255, 255, 255, 0.8);

    img {
      width: 30px;
      margin-right: 16px;
    }

    span {
      font-size: 18px;
    }
  }

  &__slider {
    position: relative;

    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 50px;
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 77%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 11px;

    width: 42px;
    height: 42px;

    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 32px;
      height: 32px;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(6px);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      left: 19px;

      width: 6px;
      height: 6px;

      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      transform: translateY(-50%) scale(0.9);

      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 11px;

    width: 42px;
    height: 42px;

    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 32px;
      height: 32px;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(6px);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      right: 19px;

      width: 6px;
      height: 6px;

      border-right: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(-45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      transform: translateY(-50%) scale(0.9);

      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  &__pagination {
    position: absolute;
    top: calc(100% + 17px);

    display: flex;
    justify-content: center;
    column-gap: 9px;
    width: 100%;

    .swiper-pagination-bullet {
      width: 5px;
      height: 5px;

      border-radius: 50%;
      background-color: #c4c4c4;

      &-active {
        background-color: map-get($colors, 'second');
      }
    }
  }

  &__text {
    margin-bottom: 17px;

    line-height: 1.5;
    text-align: center;

    p {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    span {
      color: map-get($colors, 'second');
    }
  }

  &__bold {
    font-size: 23px;
    font-weight: 700;
    line-height: 1.26;
    text-align: center;

    color: map-get($colors, 'second');
  }
}

@media (min-width: 576px) {
  .about {
    &__slider {
      margin-left: calc((100vw - 540px) / -2 - 20px);
      margin-right: calc((100vw - 540px) / -2 - 20px);
    }
  }
}

@media (min-width: 768px) {
  .about {
    padding-top: 132px;

    #about {
      top: -79px;
    }

    &__content {
      padding-top: 34px;
      padding-bottom: 58px;
    }

    &__title {
      margin-bottom: 16px;

      font-size: 55px;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 24px;
      margin-bottom: 56px;
    }

    &__item {
      width: calc(50% - 15px);
    }

    &__slider {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 56px;
    }

    &__slide {
      padding-bottom: 66%;
    }

    &__prev {
      left: 22px;

      width: 58px;
      height: 58px;

      &::before {
        width: 58px;
        height: 58px;
      }

      &::after {
        top: 24px;
        left: 26px;

        width: 9px;
        height: 9px;
      }
    }

    &__next {
      right: 22px;

      width: 58px;
      height: 58px;

      &::before {
        width: 58px;
        height: 58px;
      }

      &::after {
        top: 24px;
        right: 26px;

        width: 9px;
        height: 9px;
      }
    }

    &__pagination {
      z-index: 1;
      top: auto;
      bottom: 25px;

      column-gap: 18px;

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;

        &-active {
          background-color: map-get($colors, 'second');
        }
      }
    }

    &__text {
      margin-bottom: 34px;

      font-size: 18px;

      p {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .about {
    padding-top: 92px;

    #about {
      top: -127px;
    }

    &__content {
      padding-top: 109px;
      padding-bottom: 129px;
    }

    &__bg-1 {
      position: absolute;
      top: 160px;
      left: -40px;

      display: flex;
    }

    &__bg-2 {
      position: absolute;
      top: 598px;
      right: -10px;

      display: flex;
    }

    &__title {
      margin-bottom: 43px;
    }

    &__list {
      row-gap: 30px;
      margin-bottom: 74px;
    }

    &__item {
      flex-grow: 1;
      justify-content: center;
      width: auto;
      height: 92px;
      padding-left: 32px;
      padding-right: 32px;
    }

    &__block {
      display: flex;
    }

    &__slider {
      width: 590px;
      margin-bottom: 0;
    }

    &__slide {
      padding-bottom: 76%;
    }

    &__textual {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 44px;
    }

    &__text {
      margin-bottom: 37px;

      text-align: left;
    }

    &__bold {
      text-align: left;
    }
  }
}

@media (hover: hover) {
  .about {
    &__prev {
      &:hover {
        transform: translateY(-50%) scale(1.1);

        &:active {
          transform: translateY(-50%) scale(0.9);
        }

        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__next {
      &:hover {
        transform: translateY(-50%) scale(1.1);

        &:active {
          transform: translateY(-50%) scale(0.9);
        }

        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }
  }
}
