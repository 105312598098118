.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  border: none;
  margin-bottom: 20px;
}

.phone {
  border: none;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .module {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .name {
    width: calc(50% - 10px);
    margin-right: 10px;
    margin-bottom: 0;
  }

  .phone {
    width: calc(50% - 10px);
    margin-left: 10px;
    margin-bottom: 0;
  }

  .policy {
    margin-top: 20px;
  }
}

@media (min-width: 1200px) {
  .module {
    position: relative;
    padding-bottom: 30px;
  }

  .name {
    width: 250px;
  }

  .phone {
    width: 250px;
  }

  .policy {
    // display: none;
    position: absolute;
    bottom: 0;
  }
}
