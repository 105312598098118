$transition-time: 0.3s;

.module {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  padding-top: 18px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 18px;

  background-color: rgba(0, 0, 0, 0.3);

  &:global(.enter) {
    opacity: 0;

    .content {
      transform: translateY(120px);
    }
  }

  &:global(.enterActive) {
    transition: opacity $transition-time;
    opacity: 1;

    .content {
      transition: transform $transition-time;
      transform: translateY(0);
    }
  }

  &:global(.exit) {
    transition: $transition-time;
    opacity: 1;

    .content {
      transition: $transition-time;
      transform: translateY(0);
    }
  }

  &:global(.exitActive) {
    opacity: 0;

    .content {
      transform: translateY(120px);
    }
  }
}

.content {
  max-width: 100%;
}

@media (min-width: 768px) {
  .module {
    padding-top: 42px;
  }
}
