.module {
  position: relative;

  width: 970px;
  max-width: 100%;
  padding-top: 66px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 79px;
  overflow: hidden;

  background-color: #fff;
}

.bg1 {
  display: none;
}

.bg2 {
  display: none;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.title {
  margin-bottom: 37px;

  font-size: 23px;
  font-weight: 700;
  line-height: 1.3619;
  text-align: center;

  color: #0c6ff9;
}

.content {
  p {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 768px) {
  .module {
    padding-left: 40px;
    padding-right: 40px;
  }

  .title {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .bg1 {
    position: absolute;
    top: 50px;
    left: -30px;

    display: flex;
  }

  .bg2 {
    position: absolute;
    right: -10px;
    bottom: 310px;

    display: flex;
  }
}

@media (min-width: 1200px) {
  .module {
    padding-left: 100px;
    padding-right: 100px;
  }
}
