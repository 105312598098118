.module {
  position: relative;

  width: 770px;
  max-width: 100%;
  padding-top: 66px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 79px;
  overflow: hidden;

  background-color: #f9f9f9;
}

.bg1 {
  display: none;
}

.bg2 {
  display: none;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.form {
  position: relative;

  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 23px;

  font-size: 26px;
  font-weight: 700;
  line-height: 1.3619;
  text-align: center;

  color: #0c6ff9;
}

.arrival {
  margin-bottom: 20px;
}

.departure {
  margin-bottom: 20px;
}

.adults {
  margin-bottom: 20px;
}

.babies {
  margin-bottom: 20px;
}

.babyList {
  margin-bottom: 20px;
}

.name {
  margin-bottom: 20px;
}

.phone {
  margin-bottom: 35px;
}

.policy {
  margin-bottom: 20px;
}

.button {
  width: 100%;
  height: 55px;

  font-size: 18px;
  font-weight: 700;
  text-align: center;

  color: #fff;
  background-color: #0c6ff9;

  transition: 0.2s;

  &:active {
    background-color: darken(#0c6ff9, 10%);
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
  .bg1 {
    position: absolute;
    top: 168px;
    left: -30px;

    display: flex;
  }

  .bg2 {
    position: absolute;
    right: -10px;
    bottom: 210px;

    display: flex;
  }
}

@media (hover: hover) {
  .button {
    &:hover {
      background-color: darken(#0c6ff9, 10%);
    }
  }
}
