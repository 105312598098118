.footer {
  &__top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 60px;
  }

  &__logo {
    width: 175px;
    margin-bottom: 50px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__phone {
    margin-bottom: 22px;

    font-weight: 600;
    line-height: 1.25;

    &:active {
      color: map-get($colors, 'main');
    }
  }

  &__callback {
    width: 172px;
    height: 45px;

    font-size: 14px;
    font-weight: 600;
    text-align: center;

    border: 1px solid map-get($colors, 'main');
    color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }

  &__bottom {
    background-color: #f2f2f2;
  }

  &__bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 56px;
    padding-bottom: 56px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 37px;
    margin-bottom: 37px;

    button {
      font-size: 14px;
      line-height: 1.5;

      color: #707070;

      transition: 0.2s;

      &:active {
        color: map-get($colors, 'main');
      }
    }
  }

  &__copyright {
    font-size: 14px;
    line-height: 1.5;

    color: #707070;
  }
}

@media (min-width: 768px) {
  .footer {
    &__top-content {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 34px;
      padding-bottom: 46px;
    }

    &__logo {
      margin-bottom: 0;
    }

    // &__bottom-content {
    //   position: relative;

    //   flex-direction: row;
    //   justify-content: space-between;
    //   align-items: flex-end;
    //   padding-top: 37px;
    //   padding-bottom: 42px;
    // }

    // &__buttons {
    //   align-items: flex-start;
    //   margin-bottom: 0;

    //   button {
    //     &:last-child {
    //       position: absolute;
    //       top: 37px;
    //       right: 0;
    //     }
    //   }
    // }
  }
}

@media (min-width: 1200px) {
  .footer {
    &__top-content {
      padding-top: 23px;
      padding-bottom: 37px;
    }

    &__info {
      flex-direction: row;
    }

    &__phone {
      margin-right: 13px;
      margin-bottom: 0;
    }

    &__bottom-content {
      padding-top: 31px;
      padding-bottom: 31px;
    }

    &__buttons {
      flex-direction: row;
      column-gap: 81px;

      button {
        &:last-child {
          position: static;
        }
      }
    }
  }
}

@media (hover: hover) {
  .footer {
    &__nav {
      a {
        &:hover {
          color: map-get($colors, 'main');
        }
      }
    }

    &__phone {
      &:hover {
        span {
          color: map-get($colors, 'main');
        }
      }
    }

    &__callback {
      &:hover {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }

    &__buttons {
      button {
        &:hover {
          color: map-get($colors, 'main-dark');
        }
      }
    }
  }
}
