.beach {
  position: relative;

  background-color: #f2f2f2;

  #beach {
    position: absolute;
    top: -68px;
  }

  &__content {
    padding-top: 28px;
  }

  &__bg-1 {
    display: none;
  }

  &__bg-2 {
    display: none;
  }

  &__title {
    margin-bottom: 5px;

    font-size: 35px;
    font-weight: 700;
    line-height: 1.2286;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 17px;

    font-size: 23px;
    font-weight: 500;
    line-height: 1.42;
    text-align: center;

    color: map-get($colors, 'second');
  }

  &__text {
    margin-bottom: 47px;

    line-height: 1.5;
    text-align: center;

    p {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 74px;
    column-gap: 30px;
    row-gap: 36px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(50% - 15px);

    img {
      width: 58px;
      height: 58px;
      margin-bottom: 11px;
      object-fit: cover;

      border-radius: 50%;
    }

    span {
      font-weight: 600;
      line-height: 1.2502;
      text-align: center;
    }
  }

  &__slider {
    position: relative;

    margin-left: -20px;
    margin-right: -20px;
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 77%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 11px;

    width: 42px;
    height: 42px;

    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 32px;
      height: 32px;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(6px);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      left: 19px;

      width: 6px;
      height: 6px;

      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      transform: translateY(-50%) scale(0.9);

      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 11px;

    width: 42px;
    height: 42px;

    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 32px;
      height: 32px;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(6px);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      right: 19px;

      width: 6px;
      height: 6px;

      border-right: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(-45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      transform: translateY(-50%) scale(0.9);

      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  &__pagination {
    position: absolute;
    z-index: 1;
    bottom: 20px;

    display: flex;
    justify-content: center;
    column-gap: 9px;
    width: 100%;

    .swiper-pagination-bullet {
      width: 5px;
      height: 5px;

      border-radius: 50%;
      background-color: #c4c4c4;

      &-active {
        background-color: map-get($colors, 'second');
      }
    }
  }
}

@media (min-width: 576px) {
  .beach {
    &__slider {
      margin-left: calc((100vw - 540px) / -2 - 20px);
      margin-right: calc((100vw - 540px) / -2 - 20px);
    }
  }
}

@media (min-width: 768px) {
  .beach {
    #beach {
      top: -79px;
    }

    &__content {
      padding-top: 50px;
      padding-bottom: 60px;
    }

    &__title {
      margin-bottom: 19px;

      font-size: 55px;
      text-align: left;
    }

    &__subtitle {
      max-width: 680px;
      margin-bottom: 19px;

      font-size: 28px;
      text-align: left;
    }

    &__text {
      margin-bottom: 41px;

      font-size: 18px;
      text-align: left;

      p {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    &__list {
      column-gap: 100px;
      row-gap: 40px;
      margin-bottom: 52px;
    }

    &__item {
      flex-direction: row;
      width: calc(50% - 50px);

      img {
        width: 68px;
        height: 68px;
        margin-right: 20px;
        margin-bottom: 0;
      }

      span {
        font-size: 18px;
        text-align: left;
      }
    }

    &__slider {
      margin-left: 0;
      margin-right: 0;
    }

    &__slide {
      padding-bottom: 69%;
    }

    &__prev {
      left: 22px;

      width: 58px;
      height: 58px;

      &::before {
        width: 58px;
        height: 58px;
      }

      &::after {
        top: 24px;
        left: 26px;

        width: 9px;
        height: 9px;
      }
    }

    &__next {
      right: 22px;

      width: 58px;
      height: 58px;

      &::before {
        width: 58px;
        height: 58px;
      }

      &::after {
        top: 24px;
        right: 26px;

        width: 9px;
        height: 9px;
      }
    }

    &__pagination {
      bottom: 36px;

      column-gap: 18px;

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;

        &-active {
          background-color: map-get($colors, 'second');
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .beach {
    #beach {
      top: -127px;
    }

    &__content {
      padding-top: 95px;
      padding-bottom: 166px;
    }

    &__bg-1 {
      position: absolute;
      top: -80px;
      right: -30px;

      display: flex;
    }

    &__bg-2 {
      position: absolute;
      top: 230px;
      left: -30px;

      display: flex;
    }

    &__title {
      margin-bottom: 17px;
    }

    &__subtitle {
      max-width: none;
      margin-bottom: 25px;
    }

    &__text {
      display: flex;
      column-gap: 30px;
      margin-bottom: 50px;

      p {
        width: calc(50% - 15px);

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }

    &__list {
      column-gap: 40px;
      row-gap: 42px;
      margin-bottom: 82px;
    }

    &__item {
      width: calc(25% - 30px);
    }

    &__slider {
      .swiper {
        overflow: visible;
      }

      .swiper-slide {
        &:not(.swiper-slide-active) {
          opacity: 0.6;

          pointer-events: none;
        }
      }
    }

    &__slide {
      padding-bottom: 59%;
    }

    &__prev {
      width: 84px;
      height: 84px;

      &::before {
        width: 84px;
        height: 84px;
      }

      &::after {
        top: 37px;
        left: 38px;

        width: 12px;
        height: 12px;
      }
    }

    &__next {
      width: 84px;
      height: 84px;

      &::before {
        width: 84px;
        height: 84px;
      }

      &::after {
        top: 37px;
        right: 38px;

        width: 12px;
        height: 12px;
      }
    }

    &__pagination {
      top: calc(100% + 29px);
      bottom: auto;
    }
  }
}

@media (hover: hover) {
  .beach {
    &__prev {
      &:hover {
        transform: translateY(-50%) scale(1.1);

        &:active {
          transform: translateY(-50%) scale(0.9);
        }

        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__next {
      &:hover {
        transform: translateY(-50%) scale(1.1);

        &:active {
          transform: translateY(-50%) scale(0.9);
        }

        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }
  }
}
