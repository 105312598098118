.form {
  &__content {
    position: relative;
  }

  &__block {
    position: absolute;
    z-index: 3;
    top: -208px;

    width: 100%;
    padding-top: 18px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;

    background-color: #fff;
    box-shadow: 0px 35.4437px 46.5396px rgba(165, 93, 16, 0.0354188),
      0px 15.6202px 24.6446px rgba(165, 93, 16, 0.029952),
      0px 6.80597px 10.6311px rgba(165, 93, 16, 0.0253358),
      0px 2.48416px 2.573px rgba(165, 93, 16, 0.0197618);
  }
}

@media (min-width: 768px) {
  .form {
    &__block {
      top: -121px;

      padding-top: 40px;
      padding-left: 80px;
      padding-right: 80px;
      padding-bottom: 36px;
    }
  }
}

@media (min-width: 1200px) {
  .form {
    &__block {
      top: -92px;

      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 41px;
    }
  }
}
