.module {
  position: relative;

  width: 770px;
  max-width: 100%;
  padding-top: 66px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 13px;
  overflow: hidden;

  background-color: #f9f9f9;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.title {
  margin-bottom: 10px;

  font-size: 26px;
  font-weight: 700;
  line-height: 1.3619;
  // text-align: center;

  color: #0c6ff9;
}

.subtitle {
  margin-bottom: 23px;

  font-size: 20px;
  // font-weight: 700;
  line-height: 1.3619;
  // text-align: center;

  // color: #0c6ff9;
}

.image {
  position: relative;
  cursor: pointer;

  span {
    position: absolute;
    top: 50%;
    left: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;

    border-radius: 50%;
    background-color: #0c6ff9;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(9px);

    &::before {
      position: relative;
      left: 3px;
      top: 1px;

      height: 0;
      width: 0;

      border-bottom: 10px solid transparent;
      border-left: 17px solid #fff;
      border-top: 10px solid transparent;

      content: '';
    }
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 32px;
  }
}
