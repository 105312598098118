.union-bg {
  background-image: linear-gradient(230.87deg, #e6f8f9 0%, #f5f2ea 100%);
}

.entertainment {
  $p: &;

  position: relative;

  #entertainment {
    position: absolute;
    top: -68px;
  }

  &__content {
    padding-top: 61px;
  }

  &__bg-1 {
    display: none;
  }

  &__bg-2 {
    display: none;
  }

  &__bg-3 {
    display: none;
  }

  &__bg-4 {
    display: none;
  }

  &__title {
    margin-bottom: 12px;

    font-size: 35px;
    font-weight: 700;
    line-height: 1.2286;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 37px;

    font-size: 23px;
    font-weight: 500;
    line-height: 1.42;
    text-align: center;

    color: map-get($colors, 'second');
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    margin-bottom: 37px;

    &--all {
      margin-bottom: 0;

      #{$p}__item {
        &:nth-child(n + 10) {
          display: flex;
        }
      }

      & + #{$p}__more {
        display: none;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 445px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 33px;

    background-size: cover;
    background-position: center;

    &:nth-child(n + 10) {
      display: none;
    }
  }

  &__name {
    margin-bottom: 29px;

    font-size: 23px;
    line-height: 1.217;
    font-weight: 700;

    color: #fff;
  }

  &__desc {
    display: flex;
    align-items: flex-start;
    margin-bottom: 19px;

    img {
      width: 35px;
      margin-right: 12px;
    }

    span {
      font-weight: 600;
      line-height: 1.2502;

      color: #fff;
    }
  }

  &__distance {
    display: flex;
    align-items: center;

    img {
      width: 35px;
      margin-right: 12px;
    }

    span {
      font-weight: 600;
      line-height: 1.2502;

      color: #fff;
    }
  }

  &__more {
    display: flex;
    margin-left: auto;
    margin-right: auto;

    font-size: 18px;
    font-weight: 600;
    line-height: 1.22;

    color: #0c6ff9;
  }
}

@media (min-width: 768px) {
  .entertainment {
    #entertainment {
      top: -79px;
    }

    &__content {
      padding-top: 60px;
    }

    &__title {
      font-size: 55px;
      text-align: left;
    }

    &__subtitle {
      margin-bottom: 46px;

      font-size: 28px;
      text-align: left;
    }

    &__list {
      row-gap: 30px;
      margin-bottom: 30px;

      &--all {
        margin-bottom: 0;
      }
    }

    &__item {
      height: 440px;
      padding-left: 61px;
      padding-right: 61px;
      padding-bottom: 61px;
    }

    &__name {
      font-size: 35px;
    }

    &__desc {
      margin-bottom: 29px;

      img {
        margin-right: 16px;
      }

      span {
        max-width: 360px;

        font-size: 18px;
      }
    }

    &__distance {
      img {
        margin-right: 16px;
      }

      span {
        max-width: 360px;

        font-size: 18px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .entertainment {
    #entertainment {
      top: -127px;
    }

    &__content {
      padding-top: 119px;
    }

    &__bg-1 {
      position: absolute;
      top: -30px;
      left: -30px;

      display: flex;
    }

    &__bg-2 {
      position: absolute;
      top: 80px;
      right: 200px;

      display: flex;
    }

    &__bg-3 {
      position: absolute;
      right: -30px;
      bottom: 705px;

      display: flex;
    }

    &__bg-4 {
      position: absolute;
      left: -50px;
      bottom: -120px;

      display: flex;
    }

    &__title {
      margin-bottom: 17px;
    }

    &__subtitle {
      margin-bottom: 66px;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 30px;
      margin-bottom: 56px;

      &--all {
        margin-bottom: 0;
      }
    }

    &__item {
      width: calc(40% - 15px);
      height: 390px;
      padding-left: 58px;
      padding-right: 58px;
      padding-bottom: 52px;

      &:first-child {
        width: 100%;
        height: 445px;
      }

      &:nth-child(2),
      &:nth-child(4n + 5),
      &:nth-child(4n + 6) {
        width: calc(60% - 15px);
      }

      // &:nth-child(n + 4) {
      //   display: flex;
      // }

      // &:nth-child(n + 10) {
      //   display: none;
      // }
    }

    &__name {
      max-width: 510px;
    }
  }
}
