.calculation {
  position: relative;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(12, 111, 249, 0.93);

    content: '';
  }

  &__content {
    position: relative;

    padding-top: 61px;
    padding-bottom: 74px;
  }

  &__title {
    margin-bottom: 24px;

    font-size: 30px;
    font-weight: 700;
    line-height: 1.23;
    text-align: center;

    color: #fff;
  }

  &__text {
    margin-bottom: 24px;

    font-size: 20px;
    line-height: 1.2502;
    text-align: center;

    color: #fff;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 194px;
    height: 55px;
    flex-grow: 1;
    margin-left: auto;
    margin-right: auto;

    font-size: 18px;
    font-weight: 700;
    text-align: center;

    color: #0c6ff9;
    background-color: #fff;

    transition: 0.2s;

    &:active {
      background-color: darken(#fff, 10%);
    }
  }
}

@media (min-width: 768px) {
  .calculation {
    &__content {
      padding-top: 60px;
      padding-bottom: 83px;
    }

    &__title {
      margin-bottom: 18px;

      font-size: 35px;
    }

    &__text {
      max-width: 423px;
      margin-left: auto;
      margin-right: auto;

      font-size: 23px;
    }
  }
}

@media (min-width: 1200px) {
  .calculation {
    &__content {
      padding-top: 115px;
      padding-bottom: 134px;
    }

    &__title {
      margin-bottom: 10px;

      font-size: 55px;
    }

    &__text {
      max-width: none;
      margin-bottom: 53px;

      font-size: 28px;
    }
  }
}

@media (hover: hover) {
  .calculation {
    &__link {
      &:hover {
        color: #fff;
        background-color: #ff7e1d;
      }
    }
  }
}
