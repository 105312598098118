.module {
  width: 100%;
  height: 55px;
  padding: 0 19px;

  border: 1px solid #c0c0c0;
  background-color: #fff;

  &::placeholder {
    color: #858585;
  }
}
