.rooms {
  $p: &;

  position: relative;

  background-color: #f2f2f2;

  #rooms {
    position: absolute;
    top: -68px;
  }

  &__content {
    padding-top: 60px;
  }

  &__bg-1 {
    display: none;
  }

  &__bg-2 {
    display: none;
  }

  &__title {
    margin-bottom: 32px;

    font-size: 35px;
    font-weight: 700;
    line-height: 1.2286;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding-bottom: 30px;

    &--all {
      padding-bottom: 0;

      #{$p}__item {
        &:nth-child(n + 10) {
          display: block;
        }
      }

      & + #{$p}__more {
        display: none;
      }
    }
  }

  &__item {
    &:nth-child(n + 10) {
      display: none;
    }
  }

  &__slider {
    position: relative;
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 78%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 11px;

    width: 42px;
    height: 42px;

    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 33px;
      height: 33px;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(6px);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      left: 19px;

      width: 6px;
      height: 6px;

      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      transform: translateY(-50%) scale(0.9);

      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 11px;

    width: 42px;
    height: 42px;

    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 32px;
      height: 32px;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(6px);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      right: 19px;

      width: 6px;
      height: 6px;

      border-right: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(-45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      transform: translateY(-50%) scale(0.9);

      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  &__pagination {
    position: absolute;
    z-index: 1;
    bottom: 13px;

    display: flex;
    justify-content: center;
    column-gap: 9px;
    width: 100%;

    .swiper-pagination-bullet {
      width: 5px;
      height: 5px;

      border-radius: 50%;
      background-color: #c4c4c4;

      &-active {
        background-color: map-get($colors, 'second');
      }
    }
  }

  &__body {
    padding-top: 27px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 21px;

    background-color: #fff;
  }

  &__name {
    margin-bottom: 12px;

    font-size: 18px;
    font-weight: 700;
    line-height: 1.22;
  }

  &__price {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;

    del {
      margin-right: 13px;

      font-size: 15px;
      line-height: 1.21;
      text-decoration: line-through;

      color: rgba(0, 0, 0, 0.29);
    }

    strong {
      margin-right: 5px;

      font-size: 20px;
      font-weight: 700;
      line-height: 1.2;

      color: map-get($colors, 'second');
    }

    span {
      line-height: 1.5;
    }
  }

  &__seats {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    margin-bottom: 20px;

    p {
      display: flex;
      align-items: center;

      line-height: 1.5;

      &:last-child {
        strong {
          font-size: 14px;
          font-weight: 400;

          color: map-get($colors, 'second');
        }
      }
    }

    span {
      margin-right: 15px;
    }

    strong {
      font-weight: 700;

      color: map-get($colors, 'main');
    }
  }

  &__properties {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    margin: 0 -3.75%;
    margin-bottom: 42px;
  }

  &__property {
    position: relative;

    width: 5%;
    height: 18px;
    margin-left: 3.75%;
    margin-right: 3.75%;
    object-fit: contain;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    span {
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: calc(100% + 10px);

      padding: 10px;

      font-size: 13px;
      white-space: nowrap;

      border-radius: 3px;
      color: map-get($colors, 'main');
      background-color: #fff;
      box-shadow: 0px 15px 21px rgba(66, 66, 106, 0.1);
      transform: translateX(-50%);
      visibility: hidden;
      opacity: 0;

      transition: 0.2s;
    }

    &:nth-child(1),
    &:nth-child(9) {
      span {
        left: -10px;
        transform: translateX(0);
      }
    }

    &:nth-child(8),
    &:nth-child(16) {
      span {
        left: auto;
        right: -10px;
        transform: translateX(0);
      }
    }

    &:hover {
      span {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  &__calculation {
    flex-grow: 1;
    height: 40px;
    margin-right: 5px;

    font-size: 12px;
    font-weight: 600;
    text-align: center;

    border: 1px solid map-get($colors, 'main');
    color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }

  &__booking {
    flex-grow: 1;
    height: 40px;
    margin-left: 5px;

    font-size: 12px;
    font-weight: 600;
    text-align: center;

    color: #fff;
    background-color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      background-color: map-get($colors, 'main-dark');
    }
  }

  &__link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 40px;
    margin-left: 5px;

    font-size: 12px;
    font-weight: 600;
    text-align: center;

    color: #fff;
    background-color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      background-color: map-get($colors, 'main-dark');
    }
  }

  &__more {
    display: flex;
    margin-left: auto;
    margin-right: auto;

    font-size: 18px;
    font-weight: 600;

    color: map-get($colors, 'main');
  }
}

@media (min-width: 768px) {
  .rooms {
    $p: &;

    #rooms {
      top: -79px;
    }

    &__content {
      padding-top: 52px;
    }

    &__title {
      margin-bottom: 46px;

      font-size: 55px;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 30px;
      padding-bottom: 38px;

      &--all {
        padding-bottom: 0;

        // #{$p}__item {
        //   &:nth-child(n + 5) {
        //     display: block;
        //   }
        // }
      }
    }

    &__item {
      width: calc(50% - 15px);

      // &:nth-child(n + 4) {
      //   display: block;
      // }

      // &:nth-child(n + 5) {
      //   display: none;
      // }
    }

    &__slide {
      padding-bottom: 66%;
    }

    &__body {
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 16px;
    }

    &__name {
      margin-bottom: 6px;

      font-size: 21px;
    }

    &__price {
      margin-bottom: 25px;

      strong {
        margin-right: 6px;

        font-size: 23px;
      }

      span {
        font-size: 19px;
      }
    }

    &__seats {
      p {
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }

    &__properties {
      margin-bottom: 22px;
    }

    &__calculation {
      height: 45px;

      font-size: 15px;
    }

    &__booking {
      height: 45px;

      font-size: 15px;
    }

    &__link {
      height: 45px;

      font-size: 15px;
    }

    &__prev {
      left: 17px;

      &::before {
        width: 42px;
        height: 42px;
      }

      &::after {
        top: 18px;
        left: 19px;
      }
    }

    &__next {
      right: 17px;

      &::before {
        width: 42px;
        height: 42px;
      }

      &::after {
        top: 18px;
        right: 19px;
      }
    }

    &__pagination {
      bottom: 15px;

      column-gap: 10px;

      .swiper-pagination-bullet {
        width: 7px;
        height: 7px;

        border-radius: 50%;
        background-color: #c4c4c4;

        &-active {
          background-color: map-get($colors, 'second');
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .rooms {
    $p: &;

    #rooms {
      top: -127px;
    }

    &__content {
      padding-top: 97px;
    }

    &__bg-1 {
      position: absolute;
      top: 445px;
      left: 20px;

      display: flex;
    }

    &__bg-2 {
      position: absolute;
      top: 725px;
      right: -30px;

      display: flex;
    }

    &__title {
      margin-bottom: 44px;
    }

    &__list {
      padding-bottom: 43px;

      &--all {
        padding-bottom: 0;

        // #{$p}__item {
        //   &:nth-child(n + 10) {
        //     display: block;
        //   }
        // }
      }
    }

    &__item {
      width: calc(33.33333% - 20px);

      // &:nth-child(n + 5) {
      //   display: block;
      // }

      // &:nth-child(n + 10) {
      //   display: none;
      // }
    }

    &__slide {
      padding-bottom: 63%;
    }

    &__body {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 25px;
    }

    &__name {
      min-height: 51px;
    }

    &__properties {
      margin-bottom: 42px;
    }
  }
}

@media (hover: hover) {
  .rooms {
    &__prev {
      &:hover {
        transform: translateY(-50%) scale(1.1);

        &:active {
          transform: translateY(-50%) scale(0.9);
        }

        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__next {
      &:hover {
        transform: translateY(-50%) scale(1.1);

        &:active {
          transform: translateY(-50%) scale(0.9);
        }

        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__calculation {
      &:hover {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }

    &__booking {
      &:hover {
        background-color: map-get($colors, 'main-dark');
      }
    }

    &__link {
      &:hover {
        background-color: map-get($colors, 'main-dark');
      }
    }
  }
}
