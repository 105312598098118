.arrival {
  margin-bottom: 15px;
}

.departure {
  margin-bottom: 15px;
}

.guests {
  margin-bottom: 15px;
}

.button {
  width: 100%;
  height: 55px;

  font-size: 18px;
  font-weight: 700;
  text-align: center;

  color: #fff;
  background-color: #0c6ff9;

  transition: 0.2s;

  &:active {
    background-color: darken(#0c6ff9, 10%);
  }
}

@media (min-width: 768px) {
  .bottom {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 19px;
  }

  .arrival {
    width: calc(50% - 10px);
    margin-bottom: 0;
  }

  .departure {
    width: calc(50% - 10px);
    margin-bottom: 0;
  }

  .guests {
    width: calc(50% - 10px);
    margin-bottom: 0;
  }

  .button {
    width: calc(50% - 10px);
  }
}

@media (min-width: 1200px) {
  .bottom {
    flex-wrap: nowrap;
  }

  .arrival {
    width: 260px;
  }

  .departure {
    width: 260px;
  }

  .guests {
    width: 230px;
  }

  .button {
    width: 200px;
    flex-grow: 1;
  }
}

@media (hover: hover) {
  .button {
    &:hover {
      background-color: darken(#0c6ff9, 10%);
    }
  }
}
