.module {
  position: relative;

  width: 670px;
  max-width: 100%;
  padding-top: 66px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 58px;

  background-color: #f9f9f9;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.name {
  margin-bottom: 11px;

  font-size: 20px;
  font-weight: 600;
  line-height: 1.274;
  text-align: center;
}

.stars {
  display: flex;
  justify-content: center;
  column-gap: 6px;
  margin-bottom: 11px;
}

.text {
  margin-bottom: 10px;

  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}

.date {
  line-height: 1.5;

  color: rgba(0, 0, 0, 0.45);
}

@media (min-width: 768px) {
  .module {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .module {
    padding-left: 100px;
    padding-right: 100px;
  }

  .stars {
    justify-content: flex-start;
  }

  .name {
    text-align: left;
  }

  .text {
    text-align: left;
  }
}
