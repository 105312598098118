.header {
  $p: &;

  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100%;

  background-color: #fff;

  &__content {
    display: flex;
    align-items: center;
    height: 68px;
  }

  &__logo {
    width: 123px;
    margin-right: auto;
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 6px;
    width: 44px;
    height: 44px;

    border-radius: 50%;
    background-color: #f2f2f2;

    &::before {
      width: 16px;
      height: 1px;

      background-color: #121212;

      content: '';
    }

    span {
      width: 16px;
      height: 1px;

      background-color: #121212;
    }

    &::after {
      width: 16px;
      height: 1px;

      background-color: #121212;

      content: '';
    }
  }

  &__block {
    display: none;
  }

  &__nav {
    a {
      transition: 0.2s;
    }
  }

  &__phone {
    transition: 0.2s;
  }

  &__callback {
    transition: 0.2s;
  }

  &--open {
    #{$p}__block {
      position: fixed;
      z-index: 1;
      top: 68px;
      left: 0;

      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding-top: 30px;

      background-color: #fff;
    }

    #{$p}__nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 30px;
      margin-bottom: 60px;

      a {
        font-weight: 500;
        line-height: 1.25;

        &:active {
          color: map-get($colors, 'main');
        }
      }
    }

    #{$p}__info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #{$p}__address {
      display: none;
    }

    #{$p}__phone {
      margin-bottom: 15px;

      font-weight: 600;
      line-height: 1.25;

      &:active {
        color: map-get($colors, 'main');
      }
    }

    #{$p}__callback {
      width: 172px;
      height: 45px;
      margin-bottom: 20px;

      font-size: 14px;
      font-weight: 600;
      text-align: center;

      border: 1px solid map-get($colors, 'main');
      color: map-get($colors, 'main');

      &:active {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }

    #{$p}__instagram {
      display: flex;
      width: 30px;
      padding: 5px;
    }

    #{$p}__button {
      align-items: center;
      row-gap: 0;

      &::before {
        width: 18px;
        height: 1px;

        transform: rotate(45deg);
      }

      span {
        display: none;
      }

      &::after {
        position: relative;
        top: -1px;

        width: 18px;
        height: 1px;

        transform: rotate(-45deg);
      }
    }
  }
}

@media (min-width: 768px) {
  .header {
    $p: &;

    &__content {
      height: 79px;
    }

    &__block {
      display: flex;
    }

    &__nav {
      display: none;
    }

    &__info {
      display: flex;
      align-items: center;
      margin-right: 25px;
    }

    &__address {
      display: none;
    }

    &__phone {
      order: 1;
      margin-right: 13px;

      font-weight: 600;
      line-height: 1.25;
    }

    &__callback {
      order: 2;
      width: 172px;
      height: 45px;

      font-size: 14px;
      font-weight: 600;
      text-align: center;

      border: 1px solid map-get($colors, 'main');
      color: map-get($colors, 'main');
    }

    &__instagram {
      display: flex;
      width: 30px;
      margin-right: 17px;
      padding: 5px;
    }

    &--open {
      #{$p}__block {
        position: static;

        display: flex;
        flex-direction: row;
        padding-top: 0;
        width: auto;
        height: auto;

        background-color: transparent;
      }

      #{$p}__nav {
        position: fixed;
        z-index: 1;
        top: 79px;
        left: 0;

        display: flex;
        width: 100%;
        height: 100%;
        padding-top: 120px;

        background-color: #fff;
      }

      #{$p}__info {
        flex-direction: row;
      }

      #{$p}__phone {
        margin-bottom: 0;
      }

      #{$p}__callback {
        margin-bottom: 0;
      }
    }
  }
}

// @media (min-width: 992px) {
//   .header {
//     &__nav {
//       left: calc((100vw - 960px) / -2 - 20px);
//       right: calc((100vw - 960px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 1200px) {
  .header {
    &__content {
      height: 127px;
    }

    &__logo {
      width: 175px;
    }

    &__block {
      flex-direction: column;
      align-items: flex-end;
    }

    &__nav {
      display: flex;
      order: 1;
      column-gap: 28px;

      a {
        font-weight: 500;
        line-height: 1.25;
      }
    }

    &__info {
      margin-right: 0;
      margin-bottom: 12px;
      padding-bottom: 16px;

      border-bottom: 1px solid rgba(143, 143, 143, 0.25);
    }

    &__address {
      display: flex;
      margin-right: 30px;

      img {
        margin-right: 8px;
      }
    }

    &__button {
      display: none;
    }
  }
}

@media (hover: hover) {
  .header {
    &__nav {
      a {
        &:hover {
          color: map-get($colors, 'main-dark');
        }
      }
    }

    &__phone {
      &:hover {
        color: map-get($colors, 'main');
      }
    }

    &__callback {
      &:hover {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }
  }
}
