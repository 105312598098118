.reviews {
  position: relative;

  #reviews {
    position: absolute;
    top: -68px;
  }

  &__content {
    padding-top: 54px;
    padding-bottom: 30px;
  }

  &__bg-1 {
    display: none;
  }

  &__title {
    margin-bottom: 6px;

    font-size: 35px;
    font-weight: 700;
    line-height: 1.2286;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 34px;

    font-size: 23px;
    font-weight: 500;
    line-height: 1.42;
    text-align: center;

    color: map-get($colors, 'second');
  }

  &__slider {
    position: relative;

    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 32px;

    .swiper {
      overflow: visible;
    }

    .swiper-slide {
      &:not(.swiper-slide-active) {
        opacity: 0.6;

        pointer-events: none;
      }
    }
  }

  &__slide {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 61px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 35px;

    background-color: rgba(255, 255, 255, 0.8);

    &--cut {
      .reviews__text {
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .reviews__button {
        visibility: visible;
      }
    }
  }

  &__name {
    margin-bottom: 11px;

    font-size: 20px;
    font-weight: 600;
    line-height: 1.274;
    text-align: center;
  }

  &__stars {
    display: flex;
    justify-content: center;
    column-gap: 6px;
    margin-bottom: 11px;
  }

  &__text {
    min-height: 243px;
    margin-bottom: 10px;

    line-height: 1.5;
    text-align: center;
  }

  &__button {
    margin-bottom: 17px;

    font-size: 14px;
    font-weight: 500;
    line-height: 1.18;

    color: map-get($colors, 'second');
    visibility: hidden;

    transition: 0.2s;

    &:active {
      color: map-get($colors, 'second-light');
    }
  }

  &__date {
    line-height: 1.5;

    color: rgba(0, 0, 0, 0.45);
  }

  &__nav {
    display: none;
  }

  &__add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    margin-left: auto;
    margin-right: auto;

    font-size: 25px;
    font-weight: 700;

    border-radius: 50%;
    color: #fff;
    background-color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      background-color: map-get($colors, 'main-dark');
    }
  }
}

@media (min-width: 768px) {
  .reviews {
    #reviews {
      top: -79px;
    }

    &__content {
      position: relative;

      padding-top: 60px;
      padding-bottom: 60px;
    }

    &__title {
      margin-bottom: 17px;

      font-size: 55px;
      text-align: left;
    }

    &__subtitle {
      margin-bottom: 46px;

      font-size: 28px;
      text-align: left;
    }

    &__slider {
      margin-left: 80px;
      margin-right: 80px;
      margin-bottom: 0;
    }

    &__add {
      position: absolute;
      top: 66px;
      left: 282px;
    }
  }
}

@media (min-width: 1200px) {
  .reviews {
    #reviews {
      top: -127px;
    }

    &__content {
      padding-top: 115px;
      padding-bottom: 128px;
    }

    &__subtitle {
      margin-bottom: 56px;
    }

    &__slider {
      .swiper-slide {
        &.swiper-slide-active + .swiper-slide {
          opacity: 1;

          pointer-events: auto;
        }
      }
    }

    &__slide {
      align-items: flex-start;
      padding-top: 41px;
      padding-left: 42px;
      padding-right: 42px;
      padding-bottom: 45px;
    }

    &__name {
      margin-bottom: 21px;

      font-size: 23px;
    }

    &__text {
      text-align: left;
    }

    &__nav {
      display: flex;
    }

    &__prev {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: -42px;

      width: 84px;
      height: 84px;

      transform: translateY(-50%);

      transition: 0.2s;

      // &.swiper-button-disabled {
      //   display: none;
      // }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 84px;
        height: 84px;

        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(6px);
        transform: translate(-50%, -50%);

        transition: 0.2s;

        content: '';
      }

      &::after {
        position: absolute;
        top: 37px;
        left: 38px;

        width: 12px;
        height: 12px;

        border-left: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate(45deg);

        transition: 0.2s;

        content: '';
      }

      &:active {
        transform: translateY(-50%) scale(0.9);

        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__next {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: -42px;

      width: 84px;
      height: 84px;

      transform: translateY(-50%);

      transition: 0.2s;

      // &.swiper-button-disabled {
      //   display: none;
      // }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 84px;
        height: 84px;

        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(6px);
        transform: translate(-50%, -50%);

        transition: 0.2s;

        content: '';
      }

      &::after {
        position: absolute;
        top: 37px;
        right: 38px;

        width: 12px;
        height: 12px;

        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate(-45deg);

        transition: 0.2s;

        content: '';
      }

      &:active {
        transform: translateY(-50%) scale(0.9);

        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__add {
      top: 127px;
    }
  }
}

@media (hover: hover) {
  .reviews {
    &__button {
      &:hover {
        color: map-get($colors, 'main-dark');
      }
    }

    &__prev {
      &:hover {
        transform: translateY(-50%) scale(1.1);

        &:active {
          transform: translateY(-50%) scale(0.9);
        }

        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__next {
      &:hover {
        transform: translateY(-50%) scale(1.1);

        &:active {
          transform: translateY(-50%) scale(0.9);
        }

        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__add {
      &:hover {
        color: #fff;
        background-color: map-get($colors, 'main-dark');
      }
    }
  }
}
