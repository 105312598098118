.module {
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 205px;
}

.adults {
  border: none;
  margin-bottom: 20px;
}

.babies {
  border: none;
}

.babyList {
  border: none;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .module {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: auto;
  }

  .adults {
    width: calc(50% - 10px);
    margin-right: 10px;
    margin-bottom: 0;
  }

  .babies {
    width: calc(50% - 10px);
    margin-left: 10px;
  }

  .babyList {
    width: calc(50% - 10px);
  }
}

@media (min-width: 1200px) {
  .module {
    flex-wrap: nowrap;
  }

  .adults {
    width: 250px;
  }

  .babies {
    width: 250px;
  }

  .babyList {
    width: 300px;
    margin-top: 0;
    margin-left: 20px;
  }
}
