.module {
  width: 20px;
  height: 20px;

  border: 1px solid #0c6ff9;

  cursor: pointer;

  appearance: none;

  &:checked {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpolyline points="4 10, 8 14, 16 6" stroke="%230c6ff9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" /%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &.isInverted {
    background-color: #fff;

    &:checked {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpolyline points="4 10, 8 14, 16 6" stroke="%230C6FF9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" /%3E%3C/svg%3E');
    }
  }
}
